import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FieldLabelPipe} from '@pipes/fields/field-label.pipe';
import {ErrorPipe} from '@pipes/fields/error.pipe';
import {LocalizedDatePipe} from '@pipes/localized-date.pipe';
import {MultiLanguageTextPipe} from '@pipes/multilanguage-text.pipe';
import {EntityTypePipe} from '@pipes/entity-type.pipe';
import {TranslateModule} from '@ngx-translate/core';
import {FormatTimePipe} from './format-time.pipe';
import {GearModelItemVariationPipe} from '@pipes/gear-model-item-variation.pipe';
import {TruncatePipe} from '@pipes/truncate.pipe';
import {ActivitiesPipe} from '@pipes/activities.pipe';
import {FormatDurationPipe} from '@pipes/format-duration.pipe';
import {KmPerHToKnotsPipe} from '@pipes/kmPerHToKnots.pipe';
import {PercentagePipe} from '@pipes/percentage.pipe';
import {TextSeparatorPipe} from '@pipes/text-separator.pipe';
import {CountryNamePipe} from '@pipes/country-name.pipe';
import {NewLinesBrPipe} from '@pipes/new-lines-br.pipe';
import {SmartLabelPipe} from '@pipes/smart-label.pipe';
import {FakePipe} from "@pipes/fake.pipe";
import { DegToCompassPipe } from './deg-to-compass.pipe';

@NgModule({
  declarations: [
    FieldLabelPipe,
    ErrorPipe,
    LocalizedDatePipe,
    MultiLanguageTextPipe,
    EntityTypePipe,
    FormatTimePipe,
    GearModelItemVariationPipe,
    TruncatePipe,
    ActivitiesPipe,
    FormatDurationPipe,
    KmPerHToKnotsPipe,
    PercentagePipe,
    TextSeparatorPipe,
    CountryNamePipe,
    NewLinesBrPipe,
    SmartLabelPipe,
    FakePipe,
    DegToCompassPipe
  ],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [
    FieldLabelPipe,
    ErrorPipe,
    LocalizedDatePipe,
    MultiLanguageTextPipe,
    EntityTypePipe,
    FormatTimePipe,
    GearModelItemVariationPipe,
    TruncatePipe,
    ActivitiesPipe,
    FormatDurationPipe,
    KmPerHToKnotsPipe,
    PercentagePipe,
    TextSeparatorPipe,
    CountryNamePipe,
    NewLinesBrPipe,
    SmartLabelPipe,
    FakePipe,
    DegToCompassPipe
  ],
  providers: [
    FieldLabelPipe,
    ErrorPipe,
    LocalizedDatePipe,
    MultiLanguageTextPipe,
    EntityTypePipe,
    FormatTimePipe,
    GearModelItemVariationPipe,
    TruncatePipe,
    ActivitiesPipe,
    FormatDurationPipe,
    KmPerHToKnotsPipe,
    PercentagePipe,
    TextSeparatorPipe,
    CountryNamePipe,
    NewLinesBrPipe,
    SmartLabelPipe,
    FakePipe,
  ]
})
export class PipesModule {
}

import { ZefCollection, ZefRevisionCollection } from './zef-collection';
import { SpotLaunch } from '@model/spot-launch';
import { EntityType } from '@model/entity-type';

// Yes, this is not grammatically correct, but it's more consistent to just add a "s" at the end of the name
export const SpotLaunchs = new ZefCollection<SpotLaunch>(EntityType.spotLaunches)
  .setUniqueIndexes([
    ['shortName'],
    ['spotId', 'name']
  ]).setTextIndex([
    'name',
    'aliases',
    'abstract',
    'description'
  ]).set2dSphereIndex([
    'location'
  ])

export const SpotLaunchRevisions = new ZefRevisionCollection<SpotLaunch>(EntityType.spotLaunches);
